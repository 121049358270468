import Overwatch from './images/Overwatch.jpg';
import Menu from './components/Menu.js';
import Footer from './components/Footer.js';

function App() {
  return (
    <>
<Menu />
    <div className="App">
      <h1 className="soon">Coming Soon! </h1>
      <h1 className='soon1'>The Adventures of Lightning Pig</h1>
      <img src={Overwatch} className="watch" alt="Lightning Pig watching over the city" />
    </div>
<Footer />

    </>
    
  );
}

export default App;
