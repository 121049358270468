//Lightningpig.com
// src/Menu.js

import { useState } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

/* Import CSS Files */
import '../css/normalize.css';
import '../css/menu.css';

/* Import Site Pages */
import App from '../App.js';
/* import HomePage from '../pages/HomePage.js';
import About from '../pages/About.js';
/* import Chat from '../pages/Chat.js'; */
/* import Comics from '../pages/Comics.js';
import Contact from '../pages/Contact.js';
import Games from '../pages/Games.js'
import Shopping from '../pages/Shopping.js';
import Privacy from '../pages/Privacy.js'; */
import Logo from '../images/logo.png'; 

/* Import Components */
/* Set Variables */

/* Menu Structure*/ 
function MobileMenu(){
  return(
    <div className={"mobile-menu"}>
      <a href='#' className="menu-link">Home</a>
      <a href='#' className="menu-link">Chat</a> 
      <a href='#' className="menu-link">Comics</a>
      <a href='#' className="menu-link">Games</a>
      <a href='#' className="menu-link">Shopping</a>
      <a href='#' className="menu-link">Contact</a>
      <a href='#' className="menu-link">About</a>
      <a href='#' className="menu-link">Privacy</a>
    </div>
  );
}

function Menu() {
  let [isShown, setIsShown] = useState(false);
  const toggleMobileMenu = () => {
    setIsShown(!isShown);
    console.log({isShown});
  };

  return (
    <>
    <div className="topnav">
      <div className="siteName">
        <img src={Logo} id='logo' width={50} height={50} alt='Lightning Pig Logo'/>
        <a href='#' className="menu-link" id='siteHome' ><span>Lightning Pig</span>.com</a>
      </div>
      <div className="menu">
          <a href='#' className="menu-link">Home</a>
          {/* <a href='http://localhost:3000/Chat' className='menu-link'>Chat</a> */}
          <a href='#' className="menu-link">Comics</a>
          <a href='#' className="menu-link">Games</a>
          <a href='#' className="menu-link">Shopping</a>
          <a href='#' className="menu-link">Contact</a>
          <a href='#' className="menu-link">About</a>
          <a href='#' className="menu-link">Privacy</a>
      </div>
      <button className="show-mobile-menu-button" onClick={toggleMobileMenu}>&#8801;</button>
    </div>
    {isShown && < MobileMenu />}
    {isShown && (<button className="close-mobile-menu-button" onClick={toggleMobileMenu}> &times; </button>)}

    <div className="content">
        <BrowserRouter>
          <Routes>
            <Route path='/HomePage' element={<App/>}/>
            <Route path='/Chat' element={<App/>}/> 
            <Route path='/Comics' element={<App/>}/>
            <Route path='/Games' element={<App/>}/>
            <Route path='/Shopping' element={<App/>}/>
            <Route path='/Contact' element={<App/>}/>
            <Route path='/About' element={<App/>}/>
            <Route path='/Privacy' element={<App/>}/>
          </Routes> 
        </BrowserRouter>
        
   </div>
    </>
  );
}

export default Menu;
